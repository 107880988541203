import React from "react"
import { Link as LinkGatsby, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  Container,
  Heading,
  Text,
  Flex,
  Box,
  Link,
  Img,
  // useTheme,
} from "@chakra-ui/react"
import { Article, Nav, Header, Section, H } from "../componentsWithChakra"
import { RiPushpinFill } from "react-icons/ri"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const ConceptsPage = ({ data }) => {
  // const theme = useTheme()
  // console.log({ theme })

  //===================================================================

  let concepts = data.allMdx.group[0].edges
  if (data.allMdx.group.length === 2) {
    const conceptsPinned = data.allMdx.group[1].edges
    concepts = [...conceptsPinned, ...concepts]
  }

  //===================================================================
  return (
    <Layout>
      <SEO
        title="Concepts | THE PROJECT STATUS"
        urlPage="/concepts"
        description="Concepts in project management and how they can best be achieved by well trained project status controllers."
      />
      <Container maxW="1000px" px={4}>
        <Box as="h1" textStyle="h2" mt={8} mb={8}>
          Concepts
        </Box>

        {concepts.map(({ node }) => {
          const title = node.frontmatter.title
          const anchor = node.fields.slug
            .replace(/\/node\//g, "")
            .replace(/\//g, "")

          return (
            <Link
              as={LinkGatsby}
              key={node.fields.slug}
              to={node.fields.slug}
              id={anchor}
            >
              <Flex mb={4} align="flex-start" wrap="wrap">
                <Box w={["100%", "34%"]} pr={[0, 4]} mb={[2, 8]}>
                  <Img
                    as={GatsbyImage}
                    loading="eager"
                    image={
                      node.frontmatter.image.childImageSharp.gatsbyImageData
                    }
                    border={`1px solid #2196f3`}
                    alt="Image"
                  />
                </Box>
                <Article w={["100%", "66%"]} pl={[0, 4]} mb={[8]}>
                  <Header>
                    <Flex align="center">
                      <Box as="h2" textStyle="h3" display="flex" mr={2}>
                        {title}
                      </Box>
                      {node.frontmatter.isPinned && (
                        <Box>
                          <RiPushpinFill size={"18px"} />
                        </Box>
                      )}
                    </Flex>
                    <Text fontSize="sm" mb={2} color="grey.600">
                      {node.frontmatter.version} · {node.frontmatter.author}
                    </Text>
                  </Header>
                  <Section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: node.frontmatter.description || node.excerpt,
                      }}
                    />
                  </Section>
                </Article>
              </Flex>
            </Link>
          )
        })}
      </Container>
    </Layout>
  )
}

export default ConceptsPage

export const pageQuery = graphql`
  query MyQuery {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/concepts/" } }
      sort: { order: ASC, fields: [frontmatter___date] }
    ) {
      group(field: frontmatter___isPinned) {
        edges {
          node {
            id
            excerpt(pruneLength: 220)
            fields {
              slug
            }
            frontmatter {
              isPinned
              dateFormatted: date(formatString: "yyyy-MM-DD")
              version
              title
              description
              author
              image {
                childImageSharp {
                  gatsbyImageData(width: 400, quality: 90, layout: CONSTRAINED)
                }
              }
              border
            }
          }
        }
      }
    }
  }
`
